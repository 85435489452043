import React from 'react';
import styled from 'styled-components';
import { Text } from 'rebass';

const Label = styled(Text)`
  color: #888;
  font-weight: 700;
  font-size: 0.8rem;
`;

export default Label;
