import React from 'react';
import styled from 'styled-components';

const Ribbon = styled.div`
    :after {
        content: '${props => props.content}';
        position: absolute;
        width: 80px;
        height: 25px;
        background: ${props => props.bg};
        ${props => typeof props.top !== 'undefined' && `top: ${props.top}px;`}
        ${props =>
          typeof props.right !== 'undefined' && `right: ${props.right}px;`}
        ${props =>
          typeof props.left !== 'undefined' && `left: ${props.left}px;`}
        text-align: center;
        text-transform: capitalize;
        font-weight: bold;
        color: #fff;
        line-height: 27px;
        transform: rotate(${props => props.side === 'left' && '-'}45deg);

        font-family: 'Source Sans Pro', AdobeInvisFont, MyriadPro-Regular, sans-serif;
        font-weight: 600;
        font-size: 1rem;
    }
`;

export default Ribbon;
