import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const Container = styled(Box).attrs(props => ({
  bg: '#bafdd0',
  color: '#27613b',
  p: 12,
  py: '2px',
}))`
  border-radius: 12px;
  font-weight: 600;
  display: inline-block;
`;

export default ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
);
