import styled from 'styled-components';
import { Text } from 'rebass';

const H1 = styled(Text).attrs(() => ({
  as: 'h1',
}))`
  color: ${props => props.theme.colors.main};
`;

export default H1;
