import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import { useSpring, animated } from 'react-spring';
import useOnClickOutside from 'use-onclickoutside';

const Container = styled(animated.div)`
  width: auto;
  height: 100vh;
  top: 0;
  z-index: 1;
  overflow-y: auto;
  position: fixed;
  position: sticky;
  display: flex;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    top: 135px;
  }

  @media (max-width: 1024px) {
    position: fixed;
  }
`;

const ContentContainer = styled(Box)`
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-shadow: 3px 2px 3px 0px rgba(170, 170, 170, 0.54);
  background: rgba(255, 255, 255, 0.8);
  @media (max-width: 1024px) {
    background: #fff;
  }
`;

const ButtonContainer = styled.div`
  padding: 5px;
  box-sizing: border-box;
  padding-left: 0;
  padding-top: 0;

  @media (max-width: 1024px) {
    margin-top: 95px;
  }
`;

const StyledButton = styled(animated.button)`
  padding: 15px;
  padding-top: 10px;
  padding-left: 17px;
  width: 30px;
  border: none;
  box-shadow: 3px 2px 3px 0px rgba(170, 170, 170, 0.54);
  cursor: pointer;
  outline-color: #55b776;
  outline-width: 3px;
`;

const ToggleButton = ({ icon, showText, ...rest }) => (
  <StyledButton {...rest}>
    <Flex flexDirection="column" alignItems="center">
      {icon}
      {showText && (
        <Box
          css={`
            writing-mode: tb-rl;
            transform: rotate(-180deg);
            margin-top: 10px;
            color: #555;
          `}
        >
          Sommaire
        </Box>
      )}
    </Flex>
  </StyledButton>
);

const Icon = styled.svg`
  width: 20px;
  height: 20px;
  fill: #888;
  margin-right: -5px;
`;

const ChevronRight = () => (
  <Icon
    width="1792"
    height="1792"
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z" />
  </Icon>
);

function Sidemenu({ children }) {
  const [isShown, setIsShown] = React.useState(true);
  const [canRender, setCanRender] = React.useState(false);
  const ref = React.useRef(null);
  const contentRef = React.useRef(null);
  const shouldAnimate = isShown !== ref.current;

  useOnClickOutside(contentRef, () => {
    if (window.matchMedia('(max-width: 1024px)').matches) {
      setIsShown(false);
    }
  });

  React.useLayoutEffect(() => {
    if (window.matchMedia('(max-width: 1024px)').matches) {
      setIsShown(false);
    }
    setCanRender(true);
  }, []);

  const props = useSpring(
    shouldAnimate
      ? {
          transform: `translate3d(-${isShown ? 0 : 93}%,0,0)`,
        }
      : {}
  );
  const buttonProps = useSpring(
    shouldAnimate
      ? {
          transform: `rotate3d(0, 0, 1, ${isShown ? -180 : 0}deg) translate3d(${
            isShown ? 30 : 0
          }px,0,0)`,
          'background-color': isShown ? 'rgba(255, 255, 255, 0)' : '#fff',
          'box-shadow': isShown
            ? '3px 2px 3px 0px rgba(255, 255, 255, 0)'
            : '3px 2px 3px 0px rgba(170, 170, 170, 0.54)',
        }
      : {}
  );

  const toggleSidemenu = () => setIsShown(!isShown);
  React.useEffect(() => {
    ref.current = isShown;
  });

  if (!canRender) {
    return null;
  }

  return (
    <Container style={props}>
      <ContentContainer ref={contentRef}>
        {children({
          closeSidemenu: () => {
            if (window.matchMedia('(max-width: 1024px)').matches) {
              setIsShown(false);
            }
          },
        })}
      </ContentContainer>
      <ButtonContainer>
        <ToggleButton
          style={buttonProps}
          showText={!isShown}
          icon={<ChevronRight />}
          onClick={toggleSidemenu}
        />
      </ButtonContainer>
    </Container>
  );
}

export default Sidemenu;
