import React from 'react';
import styled from 'styled-components';
import { Text } from 'rebass';

const H2 = styled(Text).attrs(() => ({
  as: 'h2',
}))`
  color: ${props => props.theme.colors.main};
  margin: 0;
`;

export default H2;
