import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

import icons from './svg';

const Icon = styled(Box)`
  display: inline-block;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  ${props =>
    props.morphPath &&
    `path {
        d: path("${props.morphPath}");
    }`};

  > svg {
    width: ${props => props.width}px;
    height: ${props => props.height}px;

    path,
    circle,
    line,
    polyline {
      transition: all 0.2s ease;
      stroke: ${props => props.color};
      fill: ${props => props.fill};
      stroke-linejoin: round;
      stroke-linecap: round;
      stroke-width: 1.5px;
    }
  }
`;

export default ({
  color = '#fff',
  fill = 'transparent',
  width = 20,
  height = 20,
  name,
  morph,
  ...rest
}) => {
  if (!icons[name]) {
    console.log(`Icon ${name} not found`);
    return null;
  }

  const IconComponent = icons[name];

  return (
    <Icon
      width={width}
      height={height}
      color={color}
      fill={fill}
      morphPath={morph}
      {...rest}
    >
      <IconComponent />
    </Icon>
  );
};
