import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Tag = styled(Link)`
  color: ${props => props.theme.colors.main};
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 800;

  :hover {
    color: ${props => props.theme.colors.sub};
  }
`;
export default Tag;
