import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  fill: #555;
`;

export default props => (
  <Icon
    width="20"
    height="20"
    viewBox="0 0 2048 1792"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M512 448q0-53-37.5-90.5t-90.5-37.5-90.5 37.5-37.5 90.5 37.5 90.5 90.5 37.5 90.5-37.5 37.5-90.5zm1067 576q0 53-37 90l-491 492q-39 37-91 37-53 0-90-37l-715-716q-38-37-64.5-101t-26.5-117v-416q0-52 38-90t90-38h416q53 0 117 26.5t102 64.5l715 714q37 39 37 91zm384 0q0 53-37 90l-491 492q-39 37-91 37-36 0-59-14t-53-45l470-470q37-37 37-90 0-52-37-91l-715-714q-38-38-102-64.5t-117-26.5h224q53 0 117 26.5t102 64.5l715 714q37 39 37 91z" />
  </Icon>
);
